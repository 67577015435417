*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.row {
  display: flex;
  flex-wrap: wrap; /* Ensures columns wrap within the row */
  margin-right: 0;
  margin-left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
input[type="email"],input[type="text"],input[type="password"] {
  height: 50px;
}
:root {
  --primary-color: #ffffff;
  --secondary-color: #2ecc71;
  --background-color:#308992 ;
  --body-background-color:#f3f3f3;

  --border-color:#308992;

  --font-color-white: #FFFFFF;
  --font-color-darkGray:#3A3A3C;
  
  --font-Family:'DM Sans';

  --login-Text-Font:18px;
  --font-weight-600:600;
  --font-weight-500:500;
  --font-weight-400:400;
  --border-radius:10px;
  

  --font-size-heading-24:24px;
  --font-size-heading-18:18px;
  --font-size-paragraph-16:16px;

 



}