.dashboard {
    display: flex;
    height: 100vh; 
  }

.dashboard-container {
    flex-grow: 1; 
    background-color: var(--body-background-color);
    padding: 20px;
  }
  
.header {
    display: flex;
    justify-content: flex-end; 
    padding: 10px 20px;
  }
  
.profile-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.heading-content{
  display: flex;
  padding-bottom: 25px;
  color: var(--font-color-darkGray);
  font-size: var(--font-size-heading-24);
  font-weight: var(--font-weight-600);
  font-family: var(--font-Family) ;
}
.activityMain{
  display: flex;
  justify-content: space-between;
}

.recentActivity{
  width: 32%;
  background-color: var(--primary-color);
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
}
.activityList{
  padding: 1px 0px 10px 0px;
}
.recentActivityHeading{
  padding-bottom: 15px;
  color:rgba(93, 192, 202, 1);
  font-size: var(--font-size-heading-18);
  font-weight: var(--font-weight-600);
}

.summaryTip{
  width: 32%;
  background-color: var(--primary-color);
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px;   
}
.downloadReport{
  width: 32%;
  background-color: var(--primary-color);
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px;    
}
.dottedLine {
  display: block; 
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3); 
  margin: 10px 0;
}
.amountTip{
  float: right;
  color: rgba(0, 0, 0, 1);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-paragraph-16);
}
.secondSection{
  padding-top: 40px;
}
.topPerformers{
    width: 32%;
    background-color: var(--primary-color);
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    border-radius: 8px;   
}


@media only screen and (max-width: 768px) {
  .activityMain {
    flex-direction: column;

  }
  .recentActivity{
    width: 100%;
    margin-bottom: 30px;

  }
  .summaryTip{
    width: 100%;
    margin-bottom: 30px;

    
  }
  .downloadReport{
    width: 100%;
     
  }
}
