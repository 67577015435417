.date-picker-wrapper {
  width: 40%;
  position: relative;
  display: inline-block;
  
}

.date-input {
  padding-right: 40px;
}

.date-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
}
.svg-inline--fa{
    height: 2em !important;
}
.reportButtonSection{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}
.reportDownloadBtn {
  width: 70%;
  height: 60px;
  background-color: #308992;
  border: none;
  color: white;
  font-family: var(--font-Family);
  border-radius: 10px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}
.endDate{
  margin-left: 40px;
}