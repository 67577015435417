.forgetPasswordContainer {
  width: 100%;
  height: auto;
  background-image: linear-gradient(to left, #5dc0ca, #308992);
}
.forgetPassword {
  position: relative;
  font-size: 44px;
  font-weight: 600;
  font-family: "DM Sans";
}
.forgetPasswordFormContainer {
  width: 100%;
  height: 390px;
  margin-top: 22%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.forgetPasswordForm {
  width: 500px;
  padding: 15px;
}
.buttonSubmitForgetPassword {
  width: 200px;
  height: 60px;
  background-color: var(--background-color);
  border: none;
  color: white;
  font-family: var(--font-Family);
  border-radius: var(--border-radius);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: var(--font-weight-600);
  display: inline-block;
}
.cancelledForgetPassword {
  width: 200px;
  height: 60px;
  background-color: #fff;
  border: 1px solid #308992;
  color: #308992;
  font-family: var(--font-Family);
  border-radius: var(--border-radius);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}
.groupButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnDecoration {
  padding-top: 30px;
  padding-left: 19px;
  padding-right: 17px;
}
.loginLabel {
  font-size: 16px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: "DM Sans";
}
.forgetPasswordEmail {
  font-family: var(--font-Family);
  font-weight: var(--font-weight-600);
  font-size: 16px;
}
@media only screen and (max-width: 1920px) {
  .forgetPasswordFormContainer {
    width: 26vw;
  }
}

@media only screen and (max-width: 1680px) {
  .forgetPasswordFormContainer {
    width: 30vw;
  }
}

@media only screen and (max-width: 1600px) {
  .forgetPasswordFormContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 1440px) {
  .forgetPasswordFormContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 1366px) {
  .forgetPasswordFormContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .forgetPasswordFormContainer {
    width: 100%;
  }
}

@media only screen and (width: 768px) {
  .forgetPasswordContainer {
    width: 100%;
  }

  .forgetPasswordFormContainer {
    width: 76%;
    margin: 0 auto;
  }
  .forgetPassword {
    margin-top: 40px;
    margin-left: 20px;
    top: 0px;
  }
  .forgetPasswordEmail {
    margin-left: 20px;
  }
}
