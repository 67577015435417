.resetPasswordFormContainer {
  width: 100%;
  height: 472px;
  margin-top: 22%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.resetFooter {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "DM Sans";
}

.resetFooterText {
  margin-right: 5px;
}

@media only screen and (max-width: 1920px) {
  .resetPasswordFormContainer {
    width: 26vw;
  }
}

@media only screen and (max-width: 1680px) {
  .resetPasswordFormContainer {
    width: 30vw;
  }
}
@media only screen and (max-width: 1600px) {
  .resetPasswordFormContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 1440px) {
  .resetPasswordFormContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 1366px) {
  .resetPasswordFormContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .resetPasswordFormContainer {
    width: 100%;
  }
}

@media only screen and (width: 767px) {
  .resetPasswordFormContainer {
    width: 100%;
  }
  .forgetPassword {
    margin-top: 40px;
    margin-left: 20px;
    top: 0px;
  }
  .forgetPasswordEmail {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .resetPasswordFormContainer {
    width: 100%;
  }
}
