.sidebar {
  width: 260px;
  background-color: #308992;
  color: #fff;
  height: 100vh;
  transition: width 0.3s;
}

.logoImage {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
}

.sidebar-menu li {
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 600;
  color: #c7c3c3;
  font-family: "DM Sans";
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
 
}

.sidebar-menu li:hover {
  background-color: #65A5AB;
}

.sidebar-menu li.active {
  background-color: #065E66;
  color: #fff;
}

.sidebar-submenu {
  list-style-type: none;
  padding-left: 20px;
}

.sidebar-submenu li {
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 500;
  color: #d1d1d1;
  cursor: pointer;
  transition: background-color 0.3s;
}
.sidebar-submenu li.active {
  background-color: #065E66;
  color: #fff;
}
.arrow img {
  width: auto; 
  height: auto;
  padding-left: 22px;
  transition: transform 0.3s ease; 
}

.arrow.up img {
  position: relative;
  left: 35px;
  transform: rotate(180deg); 
}

.arrow.down img {
  position: relative;
  left: 35px;
  transform: rotate(0deg); 
}
