.loginContainer {
  width: 100%;
  height: auto;
  background-image: linear-gradient(to left, #5dc0ca, #308992);
}
.signIn {
  position: relative;
  top: -25px;
  font-size: 44px;
  font-weight: 600;
  font-family: "DM Sans";
}
.loginForm {
  width: 55%;
  height: 460px;
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.buttonSubmit {
  width: 460px;
  height: 60px;
  background-color: #308992;
  border: none;
  color: white;
  font-family: var(--font-Family);
  border-radius: 10px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}
.loginLabel {
  font-size: 16px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: "DM Sans";
}

@media only screen and (max-width: 1920px) {
  .loginForm {
    width: 30vw;
  }
}

@media only screen and (max-width: 1680px) {
  .loginForm {
    width: 30vw;
  }
}

@media only screen and (max-width: 1600px) {
  .loginForm {
    width: 80%;
  }
}

@media only screen and (max-width: 1440px) {
  .loginForm {
    width: 76%;
  }
}

@media only screen and (max-width: 1366px) {
  .loginForm {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .loginForm {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .loginForm {
    margin-top: 0rem;
  }
}
